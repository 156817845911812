<template>
  <AppCard class="full-height-iframe">
    <AppSpinnerBig :loading="isIframeLoading" />
    <iframe :src="iframeSrc" allow="geolocation" @load="onIframeLoaded"></iframe>
  </AppCard>
</template>

<script>
import { computed, onMounted } from '@vue/composition-api'
import { useIframeLoader } from '../composables/useIframeLoader'
import { useDashboardView } from '@galileo/composables/useDashboardView'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'

import { AppSpinnerBig, AppCard } from '@oen.web.vue2/ui'

import { useEnvStore, useAnalyticsStore } from '@galileo/stores'

export default {
  name: 'Payments',
  components: {
    AppSpinnerBig,
    AppCard,
  },
  setup() {
    const analyticsStore = useAnalyticsStore()
    const { isIframeLoading, onIframeLoaded } = useIframeLoader()
    const { mounted, unmounted } = useDashboardView()
    const envStore = useEnvStore()
    const iframeSrc = computed(() => envStore.appUrlTransfer + 'account/auth/payments')
    mounted()
    unmounted()
    onMounted(() => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.PAYMENTS_BALANCES,
        traits: {},
      })
    })
    unmounted()

    return {
      isIframeLoading,
      onIframeLoaded,
      iframeSrc,
    }
  },
}
</script>

<style scoped>
.full-height-iframe {
  height: 100vh !important;
}
.locations {
  @apply h-full w-full;

  iframe {
    @apply absolute w-full;
    @apply z-40;
    margin-top: -80px;
    height: calc(100% + 79px - 56px);

    @screen md {
      width: calc(100% - 256px);
      height: calc(100% + 79px);
    }
  }
}

.card {
  @apply mt-12 mb-0 mx-auto;
  @apply max-w-3xl;
  @apply max-h-10/12;
  @screen sm {
    @apply mb-12;
    ::v-deep .dashboard-container {
      @apply pb-0;
    }
  }
  ::v-deep .dashboard-container {
    @apply pb-0 !important;
  }
  ::v-deep .card-content {
    @apply pb-0;

    .card-content-block {
      @apply h-full;

      iframe {
        @apply h-full w-full;
        @apply z-40;
      }
    }
  }
  @screen sm {
    ::v-deep .card-content {
      @apply pb-12;
    }
  }
}
</style>
